@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic);
body,
input {
  margin: 0;
  padding: 0;
}
body {
  background: #e4ebf7;
  font-family: Roboto, "sans-serif";
}

.jYYDOk {
    font-size: 16px !important;
    line-height: 3px !important;
    font-weight: bold;
    color: 
    rgb(81, 89, 116);
    padding: 20px 10px;
}

.biTrjb {
    font-size: 16px !important;
    line-height: 3px !important;
    font-weight: 300;
    font-style: italic;
    color: 
    rgb(81, 89, 116);
}
